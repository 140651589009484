import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { fetchData, validateForm } from '../../components/Helpers/Helper'
import { REGISTER } from '../../components/Helpers/ApiRoutes'
import { UnAuthSection } from '../../components/Layouts/Layout'
import { UnAuthInputField, UnAuthPasswordField, UnAuthSubmitButton } from '../../components/Helpers/FormHelper'
import { useTranslation } from "react-i18next"

const Register = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [tinyloader, setTinyloader] = useState(false);

    const submitForm = (e) => {
        if (validateForm(e)) {
            setTinyloader(true)
            let formdata = new FormData(document.getElementById('register-form'));
            fetchData(REGISTER, 'POST', formdata, false, true, (res) => {
                setTinyloader(false)
                if (res.status) {
                    navigate("/login");
                }
            })
        }
    }

    return (
        <UnAuthSection>
            <form className="p-4 p-sm-5 w-100 needs-validation" noValidate id="register-form">
                <h4 className="heading-lines mb-4 mt-0">{t('Register')}</h4>
                <UnAuthInputField name="name" placeholder="name" required />
                <UnAuthInputField name="email" placeholder="name@example.com" required />
                <UnAuthPasswordField name="password" placeholder="********" required />
                <UnAuthSubmitButton load={tinyloader} title={t('Register')} action={submitForm} />
                <div className="d-flex justify-content-between pb-3">
                    <Link to="/login" className="anchor-blueorange fw-medium" title={t("Login")}>{t("Login")}</Link>
                </div>
            </form>
        </UnAuthSection>
    )
}

export default Register
