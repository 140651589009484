import { useEffect, useState } from "react";
import { fetchData, initialFormState, validateForm } from "../../components/Helpers/Helper";
import { AuthSection } from "../../components/Layouts/Layout";
import { InputField, ModalInputField, ModalSubmitButton, SubmitButton } from "../../components/Helpers/FormHelper";
import { LANGUAGES } from "../../components/Helpers/ApiRoutes";
import { capitalize, isArray, now } from "lodash";
import { DeleteButton } from "../../components/Helpers/ButtonHelper";
import { ConfirmationModal, ModalSection } from "../../components/Helpers/ModalHelper";
import { useTranslation } from "react-i18next"

const LanguageManage = () => {
    const { t } = useTranslation();
    const [files, setFiles] = useState([])
    const [file, setFile] = useState(null)
    const [content, setContent] = useState(null)
    const [languages, setLanguages] = useState([])
    const [delLanguage, setDelLanguage] = useState('en')
    const [language, setLanguage] = useState('en')
    const [tinyloader, setTinyloader] = useState(false);
    const [tinyloader2, setTinyloader2] = useState(false);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        setContent(null)
        fetchData(`${LANGUAGES}?language=${language}`, 'GET', '', true, true, (res) => {
            if (res.status) {
                setFile(res.data.files[0])
                setLanguages(res.data.languages)
                setFiles(res.data.files)
                setContent(res.data.file_content)
            }
        })
    }, [refresh, language])

    const resetForm = () => {
        initialFormState('create-language-Form')
    }

    const submitForm = (e) => {
        if (validateForm(e, "create-language-Form")) {
            setTinyloader(true)
            let formdata = new FormData(document.getElementById('create-language-Form'));
            fetchData(LANGUAGES, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res.status) {
                    document.getElementById('closeLanguageModal').click()
                    setRefresh(now())
                }
            })
        }
    }

    const deleteLanguage = () => {
        console.log(delLanguage);
        fetchData(`${LANGUAGES}/${delLanguage}`, 'DELETE', '', true, true, (res) => {
            if (res.status) {
                setRefresh(now())
            }
        })
    }

    const updateForm = (e) => {
        if (validateForm(e, `update-${file}-form`)) {
            console.log(132);
            setTinyloader2(true)
            let formdata = new FormData(document.getElementById(`update-${file}-form`));
            formdata.append('_method', 'PUT')
            fetchData(`${LANGUAGES}/${language}?update_file_name=${file}`, 'POST', formdata, true, true, (res) => {
                setTinyloader2(false)
            })
        }
    }

    return (
        <AuthSection title="Language Manage" btn={<button type="button" className="btn btn-blue btn-sm px-3 mb-1 me-2" data-bs-toggle="modal" data-bs-target="#createLanguageModal" title={t("Create Language")}><i className="bi bi-plus-circle-fill me-1"></i> {t("Create Language")}</button>}>
            <div className="row p-2">
                <div className="col-sm-3 p-2 bg-light border">
                    <p className="fs-20px bold text-blue"><i className="bi bi-list-task"></i> {t("Language List")}</p>
                    <hr />
                    {languages.map(lang => {
                        return <div key={lang.code} className={`rounded border mb-1 p-1 bg-${lang.code === language ? 'info' : 'white'}`}>
                            <div className="d-inline-flex w-75" onClick={() => setLanguage(lang.code)} role="button">
                                <img className="mmwh-24px" src={lang.image} alt="logo" />
                                <span className="mx-1">{lang.name} ({lang.code})</span>
                            </div>
                            <span className="float-end">{lang.code !== 'en' && <DeleteButton modal_id="#languageDeleteModal" action={() => setDelLanguage(lang.code)} />}</span>
                        </div>
                    })}

                </div>
                <div className="col-sm-9 ">
                    <ul className="nav nav-tabs loading-data" id="myTab" role="tablist">
                        {files.map((list, key) => {
                            return <li key={key} className="nav-item" role="presentation">
                                <button className={`nav-link text-blue fw-medium ${(file === list) ? "active" : ""}`} data-bs-toggle="tab" data-bs-target={list} type="button" role="tab" data-tablename={list} onClick={() => setFile(list)}>{capitalize(list)}</button>
                            </li>
                        })}
                    </ul>
                    <div className="tab-content border border-top-0">
                        {files.map((list, key) => {
                            return <div key={key} className={`tab-pane fade p-3 show ${(file === list) ? "active" : ""}`} id={list} role="tabpanel">
                                <form id={`update-${list}-form`} action="#" method="post" className="p-4 p-sm-5 needs-validation" noValidate>
                                    {content && content[list] && content[list].map((item, key) => <FileContent key={key} value={item.value} name={item.key} />)}
                                    <SubmitButton load={tinyloader2} title="UPDATE" action={updateForm} />
                                </form>
                            </div>
                        })}
                    </div>
                </div>
            </div>
            <ModalSection title={t(`Add Language`)} modal_id="createLanguageModal" size="lg" close_btn_id="closeLanguageModal" action={resetForm}>
                <form action="#" method="post" className="needs-validation" id="create-language-Form" noValidate>
                    <ModalInputField name="code" required />
                    <ModalInputField name="name" required />
                    <ModalInputField type="file" name="image" required />
                    <ModalSubmitButton load={tinyloader} title={t("Create")} action={submitForm} />
                </form>
            </ModalSection>
            <ConfirmationModal modal_id="languageDeleteModal" title={t("Delete Language")} action={deleteLanguage}>
                <p className="">{t("Are you sure want to delete this language?")}</p>
            </ConfirmationModal>
        </AuthSection>
    )
}

export default LanguageManage

export const FileContent = ({ value, name }) => {
    if (isArray(value)) return value.map((item, key) => <FileContent key={key} value={item.value} name={`${name}[${item.key}]`} />)
    else return <InputField name={name} defaultValue={value} required />
}