import { useContext } from "react";
import { Link } from "react-router-dom";
import Context from "../Helpers/Context";
import { useTranslation } from "react-i18next"

const FrontendFooter = () => {
    const { t } = useTranslation();
    const [context] = useContext(Context)
    return (
        <>
            <footer className="footer-front bg-orange">
                <div className="container position-relative">
                    <div className="row">
                        <div className="col-md-6 order-md-1 text-center text-md-end pt-3 small">
                            <ul className="p-0 m-0 list-unstyled list-inline">
                                <li className="list-inline-item">
                                    <Link className="text-white" to="/home" title={t("Home")}>{t("Home")}</Link>
                                </li>
                                <li className="list-inline-item">|</li>
                                <li className="list-inline-item">
                                    <Link className="text-white" to="/register" title={t("Register")}>{t("Register")}</Link>
                                </li>
                                <li className="list-inline-item">|</li>
                                <li className="list-inline-item">
                                    <Link className="text-white" to="/login" title={t("Login")}>{t("Login")}</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 text-center text-md-start pt-3 small text-white">
                            {context && context.site && context.site.copyright}
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default FrontendFooter