import { useContext, useEffect, useState } from "react";
import DataTables, { reloadUrlDataTable } from "../../components/Helpers/Datatables";
import { fetchData } from "../../components/Helpers/Helper";
import { AuthSection } from "../../components/Layouts/Layout";
import { GET_ROLES, USERS, USER_CHANGE_STATUS, USER_DELETE, USER_EMAIL_VERIFY } from "../../components/Helpers/ApiRoutes";
import { now } from "lodash";
import { createRoot } from "react-dom/client";
import { ActiveButton, DeactiveButton, EditButton, VerifyButton } from "../../components/Helpers/ButtonHelper";
import { DeleteButton } from "../../components/Helpers/ButtonHelper";
import { Link, useNavigate } from "react-router-dom";
import { ConfirmationModal, ZoomImage } from "../../components/Helpers/ModalHelper";
import { useTranslation } from "react-i18next"
import Context from "../../components/Helpers/Context";

const UserList = () => {
    const { t } = useTranslation();
    const [userArr] = useState([])
    const [user, setUser] = useState(null)
    const [roleFilter, setRoleFilter] = useState(false)
    const [refresh, setRefresh] = useState(false);
    const navigate = useNavigate()
    const [context] = useContext(Context)

    const [dt] = useState({
        dt_url: USERS,
        dt_name: 'user_list',
        dt_export: true,
        dt_column: [
            { data: "id", name: 'id', title: "", sortable: false, searchable: false, className: "noExport" },
            { data: "DT_RowIndex", name: 'id', title: "SR. NO", searchable: false },
            { data: "profile_image", name: 'profile_image', title: "Profile Image" },
            { data: "name", name: 'name', title: "Name" },
            { data: "role", name: 'role', title: "Role", sortable: false, searchable: false },
            { data: "permission", name: 'permission', title: "Extra Permissions", sortable: false, searchable: false, visible: context && context.auth && context.auth.id === 1 ? true: false, className: "noExport"},
            { data: "email", name: 'email', title: "Email" },
            { data: "phone", name: 'phone', title: "Phone" },
            { data: "status", name: 'status', title: "Status" },
            { data: "id", title: "Action", sortable: false, searchable: false, class: "text-nowrap noExport", width: 160 }
        ],
        dt_column_defs: [
            {
                targets: 0,
                createdCell: (td, cellData, records, row, col) => {
                    let id_deletable = !['user-manage', 'site-manage', 'role-manage', 'user-manage'].includes(records.name)
                    createRoot(td).render(
                        id_deletable && <input className="delete-user" type="checkbox" name='user_id[]' value={records.id} defaultChecked={userArr && userArr.includes(records.id)} onChange={checkUser} />
                    )
                }
            },
            {
                targets: 2,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>
                        <div className="table-imgbox table-img60" role="button" data-bs-target="#zoomImageModal" data-bs-toggle="modal" title={t("Zoom Image")} data-previewimg={records.profile_image}>
                            <img src={records.profile_image} alt={t("User")} />
                        </div>
                    </>)
                }
            },
            {
                targets: 4,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>
                        <div className="d-flex text-nowrap">
                            {records.roles.map((role) => <span key={role.id} className="badge bg-success me-1 mb-1">{role.name}</span>)}
                        </div>
                    </>)
                }
            },
            {
                targets: 5,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>
                        <div className="d-flex text-nowrap">
                            {records.permissions.map((permission) => <span key={permission.id} className="badge bg-danger me-1 mb-1">{permission.name}</span>)}
                        </div>
                    </>)
                }
            },
            {
                targets: 8,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>
                        {records.status === "1" && <span className="text-green fw-medium">Active</span>}
                        {records.status === "0" && <span className="text-danger fw-medium">Inactive</span>}
                        {records.status === "2" ? <span className="text-warning fw-medium">Pending</span> : ''}
                    </>)
                }
            },
            {
                targets: 9,
                createdCell: (td, cellData, records, row, col) => {
                    let id_deletable = !['user-manage', 'site-manage', 'role-manage', 'user-manage'].includes(records.name)
                    createRoot(td).render(<>
                        <div className="d-flex text-nowrap">
                            {records.status === "2" && <VerifyButton action={() => verifyUser(records.id)} />}
                            {records.status === "0" && <ActiveButton action={() => changeStatus(records.id)} />}
                            {records.status === "1" && <DeactiveButton action={() => changeStatus(records.id)} />}
                            <EditButton action={() => navigate('/user-edit', { state: records })} />
                            {id_deletable && <DeleteButton modal_id="#userDeleteModal" action={() => setUser(records.id)} />}
                        </div>
                    </>)
                }
            }
        ],
        dt_filter: () => {
            createRoot(document.getElementById("dt-custom-button")).render(
                <div className="d-sm-flex">
                    <button type="button" id="deleteUser" className="btn btn-sm btn-danger" data-bs-toggle="modal" data-bs-target="#userDeleteModal" disabled>{t("Delete")}</button>
                    <RoleSelect filter={setRoleFilter} />
                </div>
            )
        }
    })

    useEffect(() => {
        document.getElementById('zoomImageModal').addEventListener('show.bs.modal', function (event) {
            document.getElementById('zoomImageId').src = event.relatedTarget.dataset.previewimg
        });
    }, [])

    useEffect(() => {
        reloadUrlDataTable(dt, `${USERS}?role=${roleFilter}`)
    }, [dt, refresh, roleFilter])

    const deleteUser = () => {
        let API_URL = userArr.length ? `${USER_DELETE}?ids=${userArr}` : `${USERS}/${user}`
        fetchData(API_URL, 'DELETE', '', true, true, (res) => {
            if (res.status) {
                setRefresh(now())
            }
        })
    }

    const checkUser = (e) => {
        let val = parseInt(e.target.value)
        if (e.target.checked) {
            userArr.push(parseInt(val))
        } else {
            userArr.splice(userArr.indexOf(val), 1)
        }
        document.getElementById("deleteUser").disabled = userArr.length === 0
    }

    const changeStatus = (id) => {
        fetchData(`${USER_CHANGE_STATUS}/${id}`, 'PATCH', '', true, false, (res) => {
            setRefresh(now())
        })
    }

    const verifyUser = (id) => {
        fetchData(`${USER_EMAIL_VERIFY}/${id}`, 'PATCH', '', true, false, (res) => {
            setRefresh(now())
        })
    }

    return (
        <AuthSection title="User List" btn={<Link to='/user-create' className="btn btn-blue btn-sm px-3 me-2" title={t('Create New User')}><i className="bi bi-plus-circle-fill me-1"></i> {t("Create New User")}</Link>}>
            <DataTables dt={dt} dt_name="user_list" />
            <ConfirmationModal modal_id="userDeleteModal" title="Delete User" action={deleteUser}>
                <p className="">{t(`Are you sure want to delete ${userArr && userArr.length ? "this user" : "selected user"}?`)}</p>
            </ConfirmationModal>
            <ZoomImage modal_id="zoomImageModal" img_id="zoomImageId" />
        </AuthSection>
    )
}

export default UserList


function RoleSelect(props) {
    const [roles, setRoles] = useState([])
    const [title, setTitle] = useState(null)

    useEffect(() => {
        fetchData(GET_ROLES, 'get', '', true, true, (res) => {
            if (res.status) {
                setRoles(res.data)
            }
        })
    }, [])

    const filter = (role) => {
        setTitle(role)
        props.filter(role ? role.value : null)
    }

    return (<>
        <style jsx="true">{`
            .w-200px{
                width: 200px
            }
            .dropdown-toggle::after{
                float: right;
                margin-top: 7px;
            }
            .task-title{
                line-height: normal;
                font-size: 15px;
            }
        `}</style>
        <div className="dropdown d-inline-block align-top ms-2">
            <button className="pb-0 btn pt-1 btn-sm btn-light border align-top dropdown-toggle w-200px text-start text-truncate" data-bs-toggle="dropdown" title={title ? title.label : "All"}>
                <span className="d-inline-block p-0 mmw-170px text-truncate task-title">{title ? title.label : "All"}</span>
            </button>
            <ul className="dropdown-menu shadow border-0 w-200px p-2" style={{ maxHeight: "400px", overflow: "auto" }}>
                <li>
                    <label className={`dropdown-item rounded ${!title && "active"}`} onClick={() => filter(null)}>All</label>
                </li>
                {roles.map((role, index) => {
                    return (
                        <li key={index}>
                            <label className={`dropdown-item rounded text-truncate ${title && title.value === role.value && "active"}`} onClick={() => filter(role)}>{role.label}</label>
                        </li>
                    )
                })}
            </ul>
        </div>
    </>)
}
