import { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Context from '../../components/Helpers/Context'
import { fetchData, validateForm } from '../../components/Helpers/Helper'
import { LOGIN } from '../../components/Helpers/ApiRoutes'
import { UnAuthSection } from '../../components/Layouts/Layout'
import { UnAuthInputField, UnAuthPasswordField, UnAuthSubmitButton } from '../../components/Helpers/FormHelper'
import { useTranslation } from "react-i18next"

const Login = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [, setContext] = useContext(Context)
    const [tinyloader, setTinyloader] = useState(false);

    const submitForm = (e) => {
        if (validateForm(e)) {
            setTinyloader(true)
            let formdata = new FormData(document.getElementById('login-form'));
            fetchData(LOGIN, 'POST', formdata, false, true, (res) => {
                setTinyloader(false)
                if (res.status) {
                    localStorage.setItem("adb-token", res.data.access_token);
                    setContext(prevState => ({
                        ...prevState,
                        auth: res.data,
                    }))
                    navigate("/dashboard");
                }
            })
        }
    }

    return (
        <UnAuthSection>
            <form className="p-4 p-sm-5 w-100 needs-validation" noValidate id="login-form">
                <h4 className="heading-lines mb-4 mt-0">{t("Login")}</h4>
                <UnAuthInputField name="email" placeholder={t("name@example.com")} required />
                <UnAuthPasswordField name="password" placeholder="********" required />
                <UnAuthSubmitButton type="submit" load={tinyloader} title={t("Login")} action={submitForm} />
                <div className="d-flex justify-content-between pb-3">
                    <Link to="/forgot-password" className="anchor-blueorange fw-medium" title={`${t("Forgot Password")}?`}>{t("Forgot Password")}?</Link>
                    <Link to="/register" className="anchor-blueorange fw-medium" title={t('Register')}>{t("Register")}</Link>
                </div>
            </form>
        </UnAuthSection>
    )
}

export default Login
