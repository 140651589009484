import { useState } from "react";
import { PasswordField, SubmitButton } from "../../components/Helpers/FormHelper";
import { fetchData, initialFormState, validateForm } from "../../components/Helpers/Helper";
import { CHANGE_PASSWORD } from "../../components/Helpers/ApiRoutes";
import { AuthSection } from "../../components/Layouts/Layout";
import { useTranslation } from "react-i18next"

function ChangePassword() {
    const { t } = useTranslation();
    const [tinyloader, setTinyloader] = useState(false);

    const submitForm = (e) => {
        if (validateForm(e)) {
            setTinyloader(true)
            let formdata = new FormData(document.getElementById('change-password-form'))
            fetchData(CHANGE_PASSWORD, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res.status) {
                    initialFormState("change-password-form")
                }
            })

        }
    }

    return (
        <AuthSection title={t("Change Password")}>
            <form id="change-password-form" action="#" method="post" className="p-4 p-sm-5 needs-validation" noValidate>
                <PasswordField name="password" required />
                <PasswordField name="new_password" required />
                <PasswordField name="confirm_password" required />
                <SubmitButton load={tinyloader} title={t("Update")} action={submitForm} />
            </form>
        </AuthSection>
    )
}

export default ChangePassword;