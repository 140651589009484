export const validateCvv = (e) => {
    let cvv = e.target.value
    if (!cvv.match(/^\d{0,4}$/g)) {
        e.target.value = cvv.substr(0, cvv.length - 1)
    }
}

export const validateCardDetails = (card_number, expiry_date) => {
    let ccNumberInput = document.querySelector(card_number),
        ccNumberPattern = /^\d{0,16}$/g,
        ccNumberSeparator = " ",
        ccNumberInputOldValue,
        ccNumberInputOldCursor,

        ccExpiryInput = document.querySelector(expiry_date),
        ccExpiryPattern = /^\d{0,4}$/g,
        ccExpirySeparator = "/",
        ccExpiryInputOldValue,
        ccExpiryInputOldCursor,

        mask = (value, limit, separator) => {
            var output = [];
            for (let i = 0; i < value.length; i++) {
                if (i !== 0 && i % limit === 0) {
                    output.push(separator);
                }

                output.push(value[i]);
            }

            return output.join("");
        },
        unmask = (value) => value.replace(/[^\d]/g, ''),
        checkSeparator = (position, interval) => Math.floor(position / (interval + 1)),
        ccNumberInputKeyDownHandler = (e) => {
            let el = e.target;
            ccNumberInputOldValue = el.value;
            ccNumberInputOldCursor = el.selectionEnd;
        },
        ccNumberInputInputHandler = (e) => {
            let el = e.target,
                newValue = unmask(el.value),
                newCursorPosition;

            if (newValue.match(ccNumberPattern)) {
                newValue = mask(newValue, 4, ccNumberSeparator);

                newCursorPosition =
                    ccNumberInputOldCursor - checkSeparator(ccNumberInputOldCursor, 4) +
                    checkSeparator(ccNumberInputOldCursor + (newValue.length - ccNumberInputOldValue.length), 4) +
                    (unmask(newValue).length - unmask(ccNumberInputOldValue).length);

                el.value = (newValue !== "") ? newValue : "";
            } else {
                el.value = ccNumberInputOldValue;
                newCursorPosition = ccNumberInputOldCursor;
            }

            el.setSelectionRange(newCursorPosition, newCursorPosition);
        },
        ccExpiryInputKeyDownHandler = (e) => {
            let el = e.target;
            ccExpiryInputOldValue = el.value;
            ccExpiryInputOldCursor = el.selectionEnd;
        },
        ccExpiryInputInputHandler = (e) => {
            let el = e.target,
                newValue = el.value;

            if (e.inputType !== "deleteContentBackward" && ((newValue.length === 1 && newValue > 1) || (newValue.length === 2 && newValue > 12))) {
                newValue = `0${newValue.substr(0, 1)}/`;
            } else {
                if (e.inputType === "deleteContentBackward" && (ccExpiryInputOldCursor === 3 || ccExpiryInputOldCursor === 4)) {
                    newValue = newValue.substr(0, 2);
                } else {
                    newValue = unmask(newValue);

                    if (newValue.match(ccExpiryPattern)) {
                        var output = [];
                        for (let i = 0; i < newValue.length; i++) {
                            output.push(newValue[i]);
                            if (i === 1) {
                                output.push('/');
                            }
                        }

                        newValue = output.join("");
                    } else {
                        newValue = ccExpiryInputOldValue;
                    }
                }
            }
            el.value = newValue
        };

    ccNumberInput.removeEventListener('keydown', ccNumberInputKeyDownHandler);
    ccNumberInput.removeEventListener('input', ccNumberInputInputHandler);
    ccExpiryInput.removeEventListener('keydown', ccExpiryInputKeyDownHandler);
    ccExpiryInput.removeEventListener('input', ccExpiryInputInputHandler);

    ccNumberInput.addEventListener('keydown', ccNumberInputKeyDownHandler);
    ccNumberInput.addEventListener('input', ccNumberInputInputHandler);
    ccExpiryInput.addEventListener('keydown', ccExpiryInputKeyDownHandler);
    ccExpiryInput.addEventListener('input', ccExpiryInputInputHandler);
}