import { useEffect, useState } from "react";
import { fetchData } from "../../components/Helpers/Helper";
import { AuthSection } from "../../components/Layouts/Layout";
import { GENERATE_FILE_TOKEN, GET_FILE_MANAGER } from "../../components/Helpers/ApiRoutes";
import { useTranslation } from "react-i18next"

const FileManage = () => {
    const { t } = useTranslation();

    const [token, setToken] = useState(false)

    useEffect(() => {
        fetchData(GENERATE_FILE_TOKEN, 'POST', '', true, true, (res) => {
            if (res.status) {
                setToken(res.data)
            }
        })
    }, [])

    return (
        <AuthSection title={t("File Manage")}>
            {token && <iframe src={process.env.REACT_APP_API_URL + GET_FILE_MANAGER + "/" + token} style={{ height: "85vh", width: "100%" }} title={t("File Manager")}></iframe>}
        </AuthSection>
    )
}

export default FileManage