import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router"
import { fetchData } from "../../components/Helpers/Helper"
import { VERIFY_EMAIL } from "../../components/Helpers/ApiRoutes";
import { useTranslation } from "react-i18next"

function VerifyEmail() {
    const { t } = useTranslation();
    const location = useLocation()
    const navigate = useNavigate();

    useEffect(() => {
        if (location.search) {
            fetchData(VERIFY_EMAIL + location.search, 'POST', '', false, false, (res) => {
                if (res.status) {
                    navigate('/login')
                }
            })
        } else {
            navigate('/')
        }
    }, [navigate, location])

    return (
        <>
            <section className="about-section py-5">
                <div className="container py-sm-4 py-5">
                    <div className="row justify-content-center py-5">
                        <div className="col-sm-8 text-center">
                            <h2 className="fw-bold">{t("Account Verification")}</h2>
                            <p className="opacity-75">{t("We are verifying your account. Please do not press back button.")}</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default VerifyEmail