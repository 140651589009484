import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { GET_PERMISSIONS, GET_ROLES, USERS } from "../../components/Helpers/ApiRoutes";
import { InputField, PasswordField, SelectField, SubmitButton } from "../../components/Helpers/FormHelper";
import { fetchData, intlTel_phone, reactSelectValidation, validateForm, validatePhone } from "../../components/Helpers/Helper";
import 'intl-tel-input/build/css/intlTelInput.css';
import { AuthSection } from "../../components/Layouts/Layout";
import Select from 'react-select'
import { useTranslation } from "react-i18next"
import Context from "../../components/Helpers/Context";

function CreateUser() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [tinyloader, setTinyloader] = useState(false);
    const [user, setUser] = useState()
    const [role, setRole] = useState()
    const [selectRole, setSelectRole] = useState([])
    const [permission, setPermission] = useState([])
    const [selectPermission, setSelectPermission] = useState([])
    const [intlTel, setIntlTel] = useState()
    const location = useLocation()
    const [context] = useContext(Context)

    useEffect(() => {
        if (context && context.auth && context.auth.id === 1) {
            fetchData(GET_PERMISSIONS, 'GET', '', true, true, (res) => {
                if (res.status) {
                    setPermission(res.data)
                }
            })
        }
    }, [context])

    useEffect(() => {
        fetchData(GET_ROLES, 'GET', '', true, true, (res) => {
            if (res.status) {
                setRole(res.data)
            }
        })

        if (location && location.state) {
            setUser(location.state)
            let roles = location.state.roles;
            let role = [];
            for (let index = 0; index < roles.length; index++) {
                role[index] = { label: roles[index].name, value: roles[index].id }
            }
            let permissions = location.state.permissions;
            let permission = [];
            for (let index = 0; index < permissions.length; index++) {
                permission[index] = { label: permissions[index].name, value: permissions[index].id }
            }
            setSelectRole(role)
            setSelectPermission(permission)
            setTimeout(() => setIntlTel(intlTel_phone('#phone', '', 'us', true)), 0);
        } else {
            setIntlTel(intlTel_phone('#phone', '', 'us', true));
        }
    }, [location])

    const submitForm = (e) => {
        let phone = intlTel.getNumber()
        if (validateForm(e) && (!phone || validatePhone(intlTel, '#phone')) && reactSelectValidation([{ id: 'role', value: selectRole }])) {
            setTinyloader(true)
            let API_URL = user ? `${USERS}/${user.id}` : USERS
            let formdata = new FormData(document.getElementById('create-user-form'))
            if (user) formdata.append('_method', 'PUT')
            formdata.append('phone', phone)
            fetchData(API_URL, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res.status) {
                    navigate('/user-list')
                }
            })
        }
    }
    return (
        <AuthSection title={`${t(user ? "Update" : "Create")} ${t("User")}`} btn={<Link to='/user-list' className="btn btn-blue btn-sm me-2" title={t('User List')}><i className="bi bi-list-task"></i> {t("User List")}</Link>}>
            <form id="create-user-form" action="#" method="post" className="p-4 p-sm-5 needs-validation" noValidate>
                <InputField name="name" defaultValue={user && user.name} required />
                <SelectField name="role" required >
                    <Select required isMulti isClearable name="role[]" id="role" options={role} value={selectRole} onChange={(r) => { setSelectRole(r); reactSelectValidation([{ id: 'role', value: r }]) }} />
                </SelectField>
                {context && context.auth && context.auth.id === 1 && <>
                    <SelectField name="Extra Permission">
                        <Select isMulti isClearable name="permission[]" options={permission} value={selectPermission} onChange={(p) => setSelectPermission(p)} />
                    </SelectField>
                </>}
                <InputField name="email" defaultValue={user && user.email} required disabled={user} />
                <InputField name="phone" defaultValue={user && user.phone ? "+" + user.phone : null} />
                <PasswordField name="password" suggestion={user ? t("Leave password field blank, if you do not want to change.") : ""} required={!user} />
                <SubmitButton load={tinyloader} title={t(user ? "Update" : "Create")} action={submitForm} />
            </form>
        </AuthSection>
    )
}

export default CreateUser