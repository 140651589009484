import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseApp = initializeApp({
    apiKey: "AIzaSyAmUIBagzy-AAWmXMu2ZYUYu3y8PTNqoOA",
    authDomain: "test2-f920d.firebaseapp.com",
    projectId: "test2-f920d",
    storageBucket: "test2-f920d.appspot.com",
    messagingSenderId: "692326953877",
    appId: "1:692326953877:web:2eeb95f61b2d0825f13c9c",
    measurementId: "G-M74ZBLZ987"
});

const firebaseAuth = getAuth();

const firebaseFirestore = getFirestore(firebaseApp);
// const analytics = getAnalytics(firebaseApp);

export { firebaseApp, firebaseFirestore, firebaseAuth };