import React from "react";
import { auth } from "./firebase";
import { GoogleAuthProvider, signInWithRedirect } from "firebase/auth";

const Welcome = () => {
    const googleSignIn = () => {
        const provider = new GoogleAuthProvider();
        signInWithRedirect(auth, provider);
    };

    return (
        <main className="welcome">
            <h2>Welcome to React Chat.</h2>
            <img src="/images/logo.png" alt="ReactJs logo" width={50} height={50} />
            <p>Sign in with Google to chat with with your fellow React Developers.</p>
            <button className="sign-in">
                <img
                    onClick={googleSignIn}
                    src={"/images/logo.png"}
                    width={100}
                    alt="sign in with google"
                    type="button"
                />
            </button>
        </main>
    );
};
export default Welcome;