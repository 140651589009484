import { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import Context from "../Helpers/Context";
import ChangeLanguage from "../Lang/ChangeLanguage";
import { useTranslation } from "react-i18next"

const FrontendHeader = () => {
    const { t } = useTranslation();
    const [context] = useContext(Context)

    return (
        <nav className="navbar navbar-expand-xl navbar-light shadow-sm">
            <div className="container">
                <Link className="navbar-brand" to="/" title={t("Home")}>
                    <img src={context && context.site ? context.site.logo : 'images/logo.png'} alt={t("Logo")} />
                </Link>
                <div className="collapse navbar-collapse">
                    <ul className="navbar-nav ms-auto mb-2 mb-xl-0">
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/home">{t("Home")}</NavLink>
                        </li>
                        <li className="nav-item align-self-center ms-xl-3">
                            {localStorage.getItem("adb-token") ? <NavLink to="/dashboard" className="btn btn-orange px-4 me-1">{t("Dashboard")}</NavLink> : <>
                                <NavLink to="/login" className="btn btn-outline-blue px-4 me-1">{t("Login")}</NavLink>
                                <NavLink to="/register" className="btn btn-orange px-4">{t("Register")}</NavLink>
                            </>}
                        </li>
                        <li className="nav-item align-self-center ms-xl-3">
                            <ChangeLanguage />
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default FrontendHeader