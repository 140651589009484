// Settings Routes
export const GET_SITE_DATA = "get-site-data"
export const ADD_NEW_SETTING = 'add-new-field'
export const GET_SETTINGS = 'get-settings'
export const UPDATE_SETTINGS = 'update-settings'
export const PERMISSIONS = 'permissions'
export const GET_PERMISSIONS = 'get-permission'
export const GET_ROLES = 'get-role'
export const PERMISSION_DELETE = 'permissions-mass-destroy'
export const ROLES = 'roles'
export const ROLE_DELETE = 'roles-mass-destroy'
export const LANGUAGES = 'languages'

// Auth Routes
export const REGISTER = "register"
export const LOGIN = "login"
export const LOGOUT = "logout"
export const FORGOT_PASSWORD = "forgot-password"
export const RESET_PASSWORD = "reset-password"
export const VERIFY_EMAIL = "verify-email"
export const GET_PROFILE = "get-my-profile"
export const UPDATE_PROFILE = "update-my-profile"
export const CHANGE_PASSWORD = "change-password"
export const GET_AUTH_USER = "get-auth-user"

// Users Routes
export const USERS = 'users'
export const USER_DELETE = 'users-mass-destroy'
export const USER_CHANGE_STATUS = 'user-change-status'
export const USER_EMAIL_VERIFY = 'user-email-verify'
export const GET_ACTIVE_USER_BY_ROLE = 'get-active-user-by-role'
export const GENERATE_FILE_TOKEN = 'generate-file-token'
export const GET_FILE_MANAGER = 'get-file-manager'

// Payments Routes
export const GET_PAYMENTS = 'get-payment'
export const STRIPE_CHECKOUT = 'stripe-checkout'
export const STRIPE_PAYMENT_VERIFY = "stripe-payment-verify"
export const STRIPE_PAY_BY_CARD = 'stripe-payment-by-card'

