import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"
import { ButtonSpinner, fetchData, initialFormState, validateForm } from "../../components/Helpers/Helper";
import { UnAuthInputField, UnAuthSubmitButton } from "../../components/Helpers/FormHelper";
import { AuthSection } from "../../components/Layouts/Layout";
import { STRIPE_CHECKOUT, STRIPE_PAYMENT_VERIFY, STRIPE_PAY_BY_CARD } from "../../components/Helpers/ApiRoutes";
import { validateCardDetails, validateCvv } from "../../components/Partials/paymentHelper";

const Stripe = () => {
    const { t } = useTranslation();
    const [tinyloader, setTinyloader] = useState(false);
    const [payByCard, setPayByCard] = useState(false);
    const checkout_url = window.location.search

    useEffect(() => {
        if (checkout_url && checkout_url.includes('stripe_session_id')) {
            fetchData(`${STRIPE_PAYMENT_VERIFY}${checkout_url}`, 'GET', '', true, false, (res) => { })
        }
    }, [checkout_url])

    useEffect(() => {
        if (payByCard) {
            validateCardDetails("#card_number", "#expiry_date")
        }
    }, [payByCard])

    const purchase = () => {
        setPayByCard(false)
        setTinyloader(true)
        fetchData(STRIPE_CHECKOUT, 'POST', '', true, false, (res) => {
            setTinyloader(false);
            if (res.status) {
                window.location = res.data;
            }
        });
    }

    const pay = (e) => {
        if (validateForm(e)) {
            setTinyloader(true)
            let formdata = new FormData(document.getElementById('stripe-pay-form'))
            fetchData(STRIPE_PAY_BY_CARD, 'POST', formdata, true, true, (res) => {
                setTinyloader(false);
                if (res.status) {
                    initialFormState("stripe-pay-form")
                }
            });
        }
    }

    return (
        <AuthSection title={t("Do Payment")}>
            <div className="col-sm-12">
                <p className="fw-bold"> Pay by</p>
                <button className="btn btn-blue minw-90 me-2" title="Credit or Debit card" onClick={() => setPayByCard(!payByCard)}>Card</button>
                <button className="btn btn-info minw-90 me-2" title="Credit or Debit card" disabled={payByCard} onClick={purchase}>
                    <ButtonSpinner load={tinyloader} btnName={t("Stripe")} />
                </button>
                {payByCard && <>
                    <form id="stripe-pay-form" className="p-2 needs-validation" method="post" noValidate autoComplete="off">
                        <div className="col-12 col-md-6 col-lg-4 border p-2">
                            <UnAuthInputField name="card_number" placeholder={t("card_number")} required />
                            <UnAuthInputField name="cvv" placeholder={t("cvv")} required onInput={validateCvv} />
                            <UnAuthInputField name="expiry_date" placeholder={t("expiry_date")} required />
                            <UnAuthSubmitButton load={tinyloader} title={t("Pay")} action={pay} />
                        </div>
                    </form>
                </>}
            </div>
        </AuthSection>
    )
}

export default Stripe