import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"

function ManagePage({ code }) {
    const { t } = useTranslation();
    const [title, setTitle] = useState('Loading...')
    const [subTitle, setSubTitle] = useState('Please wait...')

    useEffect(() => {
        if (code) setTitle(code)
        if (code === 404) setSubTitle('Opps! Page Not Found')
        else if (code === 503) setSubTitle('Access Denied')
        else if (code === 200) setSubTitle('Work In progress...')
    }, [code])
    return (
        <div className="container-fluid container-common">
            <div className="row justify-content-center">
                <section className="d-flex align-items-center align-middle p-5 w-100 start-0 top-0 h-100">
                    <style jsx="true">{`
                        h1{
                            font-size: calc(  2vw + 100px);
                        }
                        h5{
                            font-size: calc( 3vw + 16px);
                        }
                    `}</style>
                    <div className="col-sm-12 text-center">
                        <h1 className="fw-bolder text-orange my-5">{code === 200 ? <i className="bi bi-emoji-smile-fill"></i> : t(title)}</h1>
                        <h5 className="fw-bolder text-orange my-5">{t(subTitle)}</h5>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default ManagePage;