import { auth } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import NavBar from "./NavBar";
import ChatBox from "./ChatBox";
import Welcome from "./Welcome";
import { AuthSection } from "../../components/Layouts/Layout";

function F_Chat() {
    const [user] = useAuthState(auth);
    return (
        <AuthSection title={"F_Chat"}>
            <div className="Chat">
                <NavBar />
                {!user ? <Welcome /> : <ChatBox />}
            </div>
        </AuthSection>
    );
}
export default F_Chat;