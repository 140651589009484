import React, { useEffect, useState } from 'react';
import { Route, Routes } from "react-router-dom";

import Context from './Helpers/Context';
import { fetchData } from './Helpers/Helper';
import Layout from './Layouts/Layout';
import ManagePage from './Layouts/ManagePage';

import Login from '../modules/Auth/Login';
import ForgotPassword from '../modules/Auth/ForgotPassword';
import ResetPassword from '../modules/Auth/ResetPassword';
import VerifyEmail from '../modules/Auth/VerifyEmail';
import ChangePassword from '../modules/Auth/ChangePassword';
import EditProfile from '../modules/Auth/Editprofile';
import Register from '../modules/Auth/Register';
import Home from '../modules/Page/Home';
import SiteManage from '../modules/Setting/SiteManage';
import Dashboard from '../modules/Dashboard/Dashboard';
import RoleManage from '../modules/Setting/RoleManage';
import PermissionManage from '../modules/Setting/PermissionManage';
import UserList from '../modules/User/UserList';
import CreateUser from '../modules/User/CreateUser';
import LanguageManage from '../modules/Setting/LanguageManage';
import FileManage from '../modules/Setting/FileManage';
import { GET_AUTH_USER, GET_SITE_DATA } from './Helpers/ApiRoutes';
import Stripe from '../modules/Payments/Stripe';
import Payment from '../modules/Payments/Payment';
import Chat from '../modules/Chat/Chat';
import F_Chat from '../modules/F_Chat/F_Chat';


export default function Routing() {
    const [context, setContext] = useState();
    const [token] = useState(localStorage.getItem('adb-token'));
    useEffect(() => {
        fetchData(GET_SITE_DATA, 'GET', '', false, false, (res) => {
            if (res.status) {
                setContext({ site: res.data })
            }
        })

        if (token) {
            fetchData(GET_AUTH_USER, 'GET', '', true, false, (res) => {
                if (res.status) {
                    setContext(prevState => ({ ...prevState, auth: res.data }))
                }
            })
        }

    }, [token]);

    const element = (can, page) => {
        let wait = (context && context.auth && context.auth.permissions && context.auth.permissions.includes(can))
        return (context && context.auth && context.auth.permissions && context.auth.permissions.includes(can)) ? page : <ManagePage code={wait === false && 503} />
    }

    return (
        <>
            <Context.Provider value={[context, setContext]}>
                <Routes>
                    {/* Normal Pages routes */}
                    <Route path="/" exact caseSensitive={false} element={<Layout front />}>
                        <Route path="/" caseSensitive={false} element={<Home />} />
                        <Route path="/home" caseSensitive={false} element={<Home />} />
                        <Route path="verify-email" caseSensitive={false} element={<VerifyEmail />} />
                    </Route>

                    {/* Unauthorized routes */}
                    <Route path="/" exact caseSensitive={false} element={<Layout redirect />}>
                        <Route path="login" caseSensitive={false} element={<Login />} />
                        <Route path="register" caseSensitive={false} element={<Register />} />
                        <Route path="forgot-password" caseSensitive={false} element={<ForgotPassword />} />
                        <Route path="reset-password/:token/:email" caseSensitive={false} element={<ResetPassword />} />
                    </Route>

                    {/* Authorized routes */}
                    <Route path="/" exact caseSensitive={false} element={<Layout authorized redirect aside header />}>
                        <Route path="dashboard" caseSensitive={false} element={<Dashboard />} />
                        <Route path="change-password" caseSensitive={false} element={<ChangePassword />} />
                        <Route path="my-profile" caseSensitive={false} element={<EditProfile />} />
                        <Route path="site-manage" caseSensitive={false} element={element('site-manage', <SiteManage />)} />
                        <Route path="role-manage" caseSensitive={false} element={element('role-manage', <RoleManage />)} />
                        <Route path="permission-manage" caseSensitive={false} element={element('permission-manage', <PermissionManage />)} />
                        <Route path="language-manage" caseSensitive={false} element={element('language-manage', <LanguageManage />)} />
                        <Route path="file-manage" caseSensitive={false} element={element('file-manage', <FileManage />)} />
                        <Route path="user-list" caseSensitive={false} element={element('user-manage', <UserList />)} />
                        <Route path="user-create" caseSensitive={false} element={element('user-manage', <CreateUser />)} />
                        <Route path="user-edit" caseSensitive={false} element={element('user-manage', <CreateUser />)} />

                        <Route path="payment-list" caseSensitive={false} element={element('payment-manage', <Payment />)} />
                        <Route path="stripe-payment" caseSensitive={false} element={element('payment-manage', <Stripe />)} />
                        <Route path="chat" caseSensitive={false} element={<Chat />} />
                        <Route path="chat2" caseSensitive={false} element={<F_Chat />} />
                    </Route>

                    <Route path="/*" caseSensitive={false} element={<ManagePage code={404} />} />
                </Routes>
            </Context.Provider>
        </>
    )
}

