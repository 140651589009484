import { useEffect, useState } from "react";
import DataTables, { reloadUrlDataTable } from "../../components/Helpers/Datatables";
import { fetchData, initialFormState, validateForm } from "../../components/Helpers/Helper";
import { AuthSection } from "../../components/Layouts/Layout";
import { ModalInputField, ModalSelectField, ModalSubmitButton } from "../../components/Helpers/FormHelper";
import { GET_PERMISSIONS, ROLES, ROLE_DELETE } from "../../components/Helpers/ApiRoutes";
import { now } from "lodash";
import { createRoot } from "react-dom/client";
import { EditButtonModal } from "../../components/Helpers/ButtonHelper";
import { DeleteButton } from "../../components/Helpers/ButtonHelper";
import Select from 'react-select'
import { ConfirmationModal, ModalSection } from "../../components/Helpers/ModalHelper";
import { useTranslation } from "react-i18next"

const RoleManage = () => {
    const { t } = useTranslation();
    const [roleArr] = useState([])
    const [role, setRole] = useState(null)
    const [permission, setPermission] = useState([])
    const [selectPermission, setSelectPermission] = useState([])
    const [tinyloader, setTinyloader] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const [dt] = useState({
        dt_url: ROLES,
        dt_name: 'role_list',
        dt_column: [
            { data: "id", name: 'id', title: "", sortable: false, searchable: false },
            { data: "DT_RowIndex", name: 'id', title: "SR. NO", searchable: false },
            { data: "name", name: 'name', title: "Name" },
            { data: "permissions", name: 'permissions', title: "Permissions", sortable: false, searchable: false },
            { data: "id", title: "Action", sortable: false, searchable: false, class: "text-nowrap noExport", width: 160 }
        ],
        dt_column_defs: [
            {
                targets: 0,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <input className="delete-role" type="checkbox" name='role_id[]' value={records.id} defaultChecked={roleArr && roleArr.includes(records.id)} onChange={checkRole} />
                    )
                }
            },
            {
                targets: 3,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>
                        <div className="d-flex text-nowrap">
                            {records.permissions.map((permission) => <span key={permission.id} className="badge bg-info me-1 mb-1">{permission.name}</span>)}
                        </div>
                    </>)
                }
            },
            {
                targets: 4,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>
                        <div className="d-flex text-nowrap">
                            <EditButtonModal modal_id="#createRoleModal" action={() => editRole(records.id)} />
                            <DeleteButton modal_id="#roleDeleteModal" action={() => setRole(records.id)} />
                        </div>
                    </>)
                }
            }
        ],
        dt_filter: () => {
            createRoot(document.getElementById("dt-custom-button")).render(
                <button type="button" id="deleterole" className="btn btn-sm btn-danger" data-bs-toggle="modal" data-bs-target="#roleDeleteModal" disabled>{t("Delete")}</button>
            )
        }
    })

    useEffect(() => {
        fetchData(GET_PERMISSIONS, 'GET', '', true, true, (res) => {
            if (res.status) {
                setPermission(res.data)
            }
        })
    }, [])

    useEffect(() => {
        reloadUrlDataTable(dt, ROLES)
    }, [dt, refresh])

    const resetForm = () => {
        setRole(null)
        initialFormState('create-role-Form')
        setSelectPermission([])
    }

    const submitForm = (e) => {
        if (validateForm(e)) {
            setTinyloader(true)
            let API_URL = role ? `${ROLES}/${role.id}` : ROLES
            let formdata = new FormData(document.getElementById('create-role-Form'));
            if (role) formdata.append('_method', 'PUT')
            fetchData(API_URL, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res.status) {
                    document.getElementById('closeRoleModal').click()
                    setRefresh(now())
                }
            })
        }
    }

    const editRole = (id) => {
        fetchData(`${ROLES}/${id}/edit`, 'GET', '', true, true, (res) => {
            if (res.status) {
                setRole(res.data)
                setSelectPermission(res.data.permissions)
            }
        })
    }

    const deleteRole = () => {
        let API_URL = roleArr.length ? `${ROLE_DELETE}?ids=${roleArr}` : `${ROLES}/${role}`
        fetchData(API_URL, 'DELETE', '', true, true, (res) => {
            if (res.status) {
                resetForm()
                setRefresh(now())
            }
        })
    }

    const checkRole = (e) => {
        let val = parseInt(e.target.value)
        if (e.target.checked) {
            roleArr.push(parseInt(val))
        } else {
            roleArr.splice(roleArr.indexOf(val), 1)
        }
    }

    return (
        <AuthSection title={t("Role Manage")} btn={<button type="button" className="btn btn-blue btn-sm px-3 mb-1 me-2" data-bs-toggle="modal" data-bs-target="#createRoleModal" title={t("Create Role")}><i className="bi bi-plus-circle-fill me-1"></i> {t("Create Role")}</button>}>
            <DataTables dt={dt} dt_name="role_list" />
            <ModalSection title={t(`${role ? "Edit" : "Add"} Role`)} modal_id="createRoleModal" size="lg" close_btn_id="closeRoleModal" action={resetForm}>
                <form action="#" method="post" className="needs-validation" id="create-role-Form" noValidate>
                    <ModalInputField name="name" defaultValue={role && role.name ? role.name : ""} required />
                    <ModalSelectField name="Permission">
                        <Select isMulti isClearable name="permission[]" options={permission} value={selectPermission} onChange={(v) => setSelectPermission(v)} />
                    </ModalSelectField>
                    <ModalSubmitButton load={tinyloader} title={t(role ? "Update" : "Create")} action={submitForm} />
                </form>
            </ModalSection>
            <ConfirmationModal modal_id="roleDeleteModal" title="Delete Role" action={deleteRole} closeModal={resetForm}>
                <p className="">{t(`Are you sure want to delete ${roleArr && roleArr.length ? "this role" : "selected role"}?`)}</p>
            </ConfirmationModal>
        </AuthSection>
    )
}

export default RoleManage