import { useContext, useEffect, useState } from "react";
import { GET_PROFILE, UPDATE_PROFILE } from "../../components/Helpers/ApiRoutes";
import { InputField, SubmitButton } from "../../components/Helpers/FormHelper";
import { fetchData, intlTel_phone, validateForm, validatePhone } from "../../components/Helpers/Helper";
import Context from "../../components/Helpers/Context";
import { AuthSection } from "../../components/Layouts/Layout";
import { useTranslation } from "react-i18next"

function Editprofile() {
    const { t } = useTranslation();
    const [context, setContext] = useContext(Context);
    const [tinyloader, setTinyloader] = useState(false);
    const [data, setData] = useState()
    const [intlTel, setIntlTel] = useState()

    useEffect(() => {
        fetchData(GET_PROFILE, 'GET', '', true, false, (res) => {
            if (res.status) {
                setData(res.data)
                setTimeout(() => setIntlTel(intlTel_phone('#phone', '', 'us', true)), 0);
            }
        })
    }, [])

    const submitForm = (e) => {
        let phone = intlTel.getNumber()
        if (validateForm(e) && (!phone || validatePhone(intlTel, '#phone'))) {
            setTinyloader(true)
            let formdata = new FormData(document.getElementById('my-profile-form'))
            formdata.append('phone', phone)
            fetchData(UPDATE_PROFILE, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res.status) {
                    setContext(prevState => ({
                        ...prevState,
                        auth: { ...context.auth, profile_image: res.data.profile_image }
                    }))
                }
            })

        }
    }
    return (
        <AuthSection title={t("My Profile")}>
            <form id="my-profile-form" action="#" method="post" className="p-4 p-sm-5 needs-validation" noValidate>
                <InputField name="name" defaultValue={data && data.name} required />
                <InputField name="email" defaultValue={data && data.email} disabled required />
                <InputField name="phone" defaultValue={data && data.phone ? "+" + data.phone : null} />
                <InputField type="file" name="profile_image" />
                <SubmitButton load={tinyloader} title={t("Update")} action={submitForm} />
            </form>
        </AuthSection>
    )
}

export default Editprofile;