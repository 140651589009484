import { useEffect, useState } from "react";
import DataTables, { reloadUrlDataTable } from "../../components/Helpers/Datatables";
import { AuthSection } from "../../components/Layouts/Layout";
import { GET_PAYMENTS } from "../../components/Helpers/ApiRoutes";
import { useTranslation } from "react-i18next"
const Payment = () => {
    const { t } = useTranslation();

    const [dt] = useState({
        dt_url: GET_PAYMENTS,
        dt_name: 'payment_list',
        dt_column: [
            { data: "DT_RowIndex", name: 'id', title: "SR. NO", searchable: false },
            { data: "name", name: 'name', title: "Name" },
        ],
    })

    useEffect(() => {
        reloadUrlDataTable(dt, GET_PAYMENTS)
    }, [dt])

    return (
        <AuthSection title={t("Payment List")}>
            <DataTables dt={dt} dt_name="payment_list" />
        </AuthSection>
    )
}

export default Payment