import React from "react";
import "firebase/firestore";
import "firebase/auth";
import "firebase/analytics";

import { getAnalytics } from "firebase/analytics";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "./load";
import Logout from "./Logout";
import Login from "./Login";
import ChatRoom from "./ChatRoom";
import { AuthSection } from "../../components/Layouts/Layout";

getAnalytics();

function Chat() {
  const [user] = useAuthState(firebaseAuth);
  return (
    <AuthSection title={"Chat"}>
      <div className="App">
        <header>
          {user ? <h2>{`Welcome ${user?.displayName}`}</h2> : <></>}
          <Logout />
        </header>

        <section>{user ? <ChatRoom /> : <Login />}</section>
      </div>
    </AuthSection>
  );
}

export default Chat;
