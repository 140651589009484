import { useTranslation } from "react-i18next"

export const ModalSection = (props) => {
    return (
        <div className="modal fade" id={props.modal_id} tabIndex="-1" aria-labelledby={props.modal_id} aria-hidden="true" data-bs-backdrop="static" data-attr={props.data_attr ?? null}>
            <div className={`modal-dialog modal-dialog-scrollable modal-${props.size}`}>
                <div className="modal-content border-0 shadow">
                    <div className="modal-header bg-light">
                        <h5 className="modal-title">{props.title}</h5>
                        <button id={props.close_btn_id ?? null} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.action ?? null}></button>
                    </div>
                    <div className="modal-body p-sm-4" id={props.modal_body_id ?? null}>{props.children}</div>
                </div>
            </div>
        </div>
    )
}

export const ConfirmationModal = (props) => {
    const { t } = useTranslation();
    return (
        <div className="modal fade" id={props.modal_id ?? "confirmationModal"} tabIndex="-1" aria-labelledby={props.modal_id} aria-hidden="true" data-bs-backdrop="static">
            <div className={`modal-dialog modal-dialog-scrollable modal-${props.size ?? 'md'}`}>
                <div className="modal-content border-0 shadow">
                    <div className="modal-header bg-light">
                        <h5 className="modal-title">{props.title ?? "Detele Item"}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.closeModal ?? null}></button>
                    </div>
                    <div className="modal-body p-sm-4">{props.children ?? <p className="">{t("Are you sure want to delete this item?")}</p>}</div>
                    <div className="modal-footer bg-light">
                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={props.action ?? null}>{props.operation ?? 'Delete'}</button>
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={props.closeModal ?? null}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const ZoomImage = (props) => {
    const { t } = useTranslation();
    return (
        <div className="modal fade" id={props.modal_id ?? "zoomImage"} tabIndex="-1" aria-labelledby={props.modal_id} aria-hidden="true" data-bs-backdrop="static">
            <div className={`modal-dialog modal-dialog-centered modal-${props.size ?? 'lg'}`}>
                <div className="modal-content border-0 shadow">
                    <div className="modal-header bg-light">
                        <h5 className="modal-title">{props.title ?? "View Image"}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="moday-body align-items-center justify-content-center w-100" style={{ minHeight: '60vh' }}>
                        <img src="" id={props.img_id} alt={t("Zoom")} className="img-cover" style={{ 'height': 'inherit', 'width': 'inherit' }} />
                    </div>
                </div>
            </div>
        </div>
    )
}